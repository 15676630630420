<template>
  <div class="article">
    <div class="contents tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col
              v-for="(n, index) in openTabIndex == 3
                ? managementMode
                  ? 6
                  : 5
                : 4"
              :key="index"
            />
          </colgroup>
          <thead>
            <tr>
              <th v-if="openTabIndex == 3">검사구분</th>
              <th>검사항목</th>
              <th>합격기준</th>
              <th>검사기구</th>
              <th>검사시기</th>
              <th v-if="managementMode && openTabIndex == 3">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(veri, index) in FilteredVeri"
              :key="veri.id"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index && openTabIndex == 3 }"
            >
              <td v-if="openTabIndex == 3">
                {{ getVerificationType(veri.verification_type_id) }}
              </td>
              <td>{{ veri.inspection_item }}</td>
              <td>{{ veri.pass_standard }}</td>
              <td>{{ veri.inspection_equipment }}</td>
              <td>{{ veri.inspection_timing }}</td>
              <td
                v-if="managementMode && openTabIndex == 3"
                @click="my_isModalOpen = true"
              >
                <button class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
            <tr
              class="new"
              v-if="managementMode && openTabIndex == 3"
              :class="{ active: selectedIndex == -1 }"
              @click="selectRow(-1)"
            >
              <td colspan="6">+신규 항목 등록</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-if="openTabIndex == 3">
      <div class="head">
        <h5>검사항목 정보</h5>
        <div>
          <label>검사구분</label>
          <div class="input_checkbox">
            <label
              for="checkbox1"
              class="chk_box"
              :class="{
                active: managementData.verification_type_id % 2 == 1,
              }"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox1"
              @click="toggleCheckBox(1)"
              :disabled="!managementMode"
            />
            <label for="checkbox1" class="label_txt">원자재</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox2"
              class="chk_box"
              :class="{
                active:
                  (managementData.verification_type_id >= 2 &&
                    managementData.verification_type_id <= 3) ||
                  managementData.verification_type_id >= 6,
              }"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox2"
              @click="toggleCheckBox(2)"
              :disabled="!managementMode"
            />
            <label for="checkbox2" class="label_txt">공정</label>
          </div>
          <div class="input_checkbox">
            <label
              for="checkbox3"
              class="chk_box"
              :class="{
                active: managementData.verification_type_id >= 4,
              }"
            >
              <i class="fa fa-check"></i>
            </label>
            <input
              type="checkbox"
              id="checkbox3"
              @click="toggleCheckBox(4)"
              :disabled="!managementMode"
            />
            <label for="checkbox3" class="label_txt">제품</label>
          </div>
        </div>
      </div>
      <div class="form">
        <form>
          <div class="input_text">
            <label>검사항목</label>
            <input
              type="text"
              v-model="managementData.inspection_item"
              :readonly="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>합격기준</label>
            <input
              type="text"
              v-model="managementData.pass_standard"
              :readonly="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>검사기구</label>
            <input
              type="text"
              v-model="managementData.inspection_equipment"
              :readonly="!managementMode"
            />
          </div>
          <div class="input_text">
            <label>검사시기</label>
            <input
              type="text"
              v-model="managementData.inspection_timing"
              :readonly="!managementMode"
            />
          </div>
        </form>
      </div>
      <div class="btn_wrap">
        <button
          class="btn_sub2"
          @click.prevent="submitForm()"
          v-if="managementMode"
          :disabled="isValidModify || chkValidation"
        >
          저장
        </button>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          deleteVefi($event);
          my_isModalOpen = false;
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import spinnerMixin from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  mixins: [fetchMixin, modalMixin, spinnerMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //delete modal
      my_modal_title: '정말로 삭제하시겠습니까?',
      my_modal_content: '삭제 후 복구할 수 없습니다.',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromQualityManagementPage',
      selectedIndex: 'getSelectedIndexFromQualityManagement',
      managementData: 'getManagementDataFromQualityManagement',
      base_verifications: 'getBaseVerificationFromQualityManagement',
      openTabIndex: 'getOpenTabIndexFromQualityManagementPage',
    }),
    FilteredVeri() {
      if (this.openTabIndex != 3) {
        if (this.openTabIndex == 0) {
          return this.base_verifications.filter(
            x => x.verification_type_id % 2 == 1,
          );
        } else if (this.openTabIndex == 1) {
          return this.base_verifications.filter(
            x =>
              [2, 3].includes(x.verification_type_id) ||
              x.verification_type_id >= 6,
          );
        } else {
          return this.base_verifications.filter(
            x => x.verification_type_id >= 4,
          );
        }
      } else {
        return this.base_verifications;
      }
    },
    isValidModify() {
      if (this.managementMode && this.selectedIndex != -1) {
        const modifyData = JSON.stringify(this.managementData);
        const originData = JSON.stringify(
          this.base_verifications[this.selectedIndex],
        );
        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
    chkValidation() {
      if (
        this.managementData.inspection_item == '' ||
        this.managementData.pass_standard == '' ||
        this.managementData.inspection_equipment == '' ||
        this.managementData.inspection_timing == ''
      ) {
        return true;
      } else return false;
    },
  },
  methods: {
    async submitForm() {
      const payload = this.lodash.clonedeep(this.managementData);
      if (payload.verification_type_id == 0) {
        this.openOneButtonModal(
          '등록 불가',
          '반드시 하나이상의 검사 구분을 체크해주세요',
        );
        return;
      } else if (payload.inspection_item == '') {
        this.openOneButtonModal('등록 불가', '검사항목은 필수값입니다.');
        return;
      }
      if (payload.id == undefined) {
        this.showSpinner();
        await this.$store
          .dispatch('INSERT_VERIFICATION', payload)
          .then(async () => {
            //fetch
            await this.FETCH('FETCH_BASE_VERIFICATION');
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('등록 실패', '등록 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        this.showSpinner();
        await this.$store
          .dispatch('UPDATE_VERIFICATION', payload)
          .then(async () => {
            //fetch
            await this.FETCH('FETCH_BASE_VERIFICATION');
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async deleteVefi() {
      const target = this.lodash.clonedeep(
        this.base_verifications[this.selectedIndex],
      );
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_VERIFICATION', target.id)
        .then(async () => {
          //fetch
          await this.FETCH('FETCH_BASE_VERIFICATION');
          this.selectRow(-1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 실패', '삭제 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getVerificationType(type) {
      switch (type) {
        case 1:
          return '원자재';
        case 2:
          return '공정';
        case 3:
          return '원자재, 공정';
        case 4:
          return '제품';
        case 5:
          return '원자재, 제품';
        case 6:
          return '공정, 제품';
        case 7:
          return '원자재, 공정, 제품';
        default:
          return '';
      }
    },
    selectRow(index) {
      if (this.openTabIndex != 3) {
        return;
      }
      this.$store.commit('setSelectedIndexToQualityManagement', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToQualityManagement',
          this.lodash.clonedeep(this.base_verifications[index]),
        );
      } else {
        this.$store.commit('setManagementDataToQualityManagement', {
          verification_type_id: 0,
          inspection_item: '',
          pass_standard: '',
          inspection_equipment: '',
          inspection_timing: '',
        });
      }
    },
    toggleCheckBox(type_value) {
      console.log('ind');
      if (type_value == 4) {
        //완제품
        if (this.managementData.verification_type_id >= 4) {
          //   this.$store.commit('subVerificationTypeId', 4);
          this.managementData.verification_type_id -= 4;
        } else {
          this.managementData.verification_type_id += 4;
          //   this.$store.commit('addVerificationTypeId', 4);
        }
      } else if (type_value == 2) {
        //공정
        if (
          (this.managementData.verification_type_id >= 2 &&
            this.managementData.verification_type_id <= 3) ||
          this.managementData.verification_type_id >= 6
        ) {
          this.managementData.verification_type_id -= 2;
          //   this.$store.commit('subVerificationTypeId', 2);
        } else {
          this.managementData.verification_type_id += 2;
          //   this.$store.commit('addVerificationTypeId', 2);
        }
      } else {
        //수입검사
        if (this.managementData.verification_type_id % 2 == 1) {
          //   this.$store.commit('subVerificationTypeId', 1);
          this.managementData.verification_type_id -= 1;
        } else {
          //   this.$store.commit('addVerificationTypeId', 1);
          this.managementData.verification_type_id += 1;
        }
      }
    },
  },
  async created() {
    if (this.base_verifications.length < 1) {
      await this.FETCH('FETCH_BASE_VERIFICATION');
    }
  },
};
</script>

<style lang="scss" scoped></style>
