var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    manage_inspection_list: _vm.tabIndex == 3,
    inspection_list: _vm.tabIndex != 3,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToQualityManagementPage',"options":[
          { title: '원자재 검사', name: 'material_inspection' },
          { title: '공정 검사', name: 'fare_inspection' },
          { title: '제품 검사', name: 'product_inspection' },
          { title: '검사항목 관리', name: 'inspection_management' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),(_vm.isPermissionOn)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 3),expression:"tabIndex == 3"}],class:_vm.managementMode ? 'btn_admin on' : 'btn_admin',attrs:{"href":"#"},on:{"click":function($event){return _vm.ToggleManagementMode()}}},[_vm._v("관리")]):_vm._e(),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:_vm.tabIndex == 0 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("원자재 검사")])]),_c('li',{class:_vm.tabIndex == 1 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("공정검사")])]),_c('li',{class:_vm.tabIndex == 2 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("제품검사")])]),_c('li',{class:_vm.tabIndex == 3 ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("검사항목 관리")])])])]):_vm._e(),_c('quality-management-form')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }